.colorNone {
  @apply bg-transparent border-transparent;
}

.colorWhiteDefault {
  @apply bg-white border-white text-gray-800;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply bg-gray-100 border-gray-100;
    }
  }
}

.colorWhiteOutline {
  @apply border-white text-white;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply opacity-75;
    }
  }
}

.colorPrimaryDefault {
  @apply bg-theme-primary border-theme-primary text-white;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply bg-theme-primary-light border-theme-primary-light;
    }
  }
}

.colorPrimaryOutline {
  @apply border-theme-primary text-theme-primary;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply border-theme-primary-light text-theme-primary-light;
    }
  }
}

.colorSecondaryDefault {
  @apply bg-theme-secondary border-theme-secondary text-white;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply bg-theme-secondary-light border-theme-secondary-light;
    }
  }
}

.colorSecondaryOutline {
  @apply border-theme-secondary text-theme-secondary;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply border-theme-secondary-light text-theme-secondary-light;
    }
  }
}

.colorTealDefault {
  @apply bg-teal-500 border-teal-500 text-white;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply bg-teal-300 border-teal-300;
    }
  }
}

.colorTealOutline {
  @apply border-teal-500 text-teal-500;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply border-teal-300 text-teal-300;
    }
  }
}

.colorGreenDefault {
  @apply bg-green-500 border-green-500 text-white;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply bg-green-300 border-green-300;
    }
  }
}

.colorGreenOutline {
  @apply border-green-500 text-green-500;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply border-green-300 text-green-300;
    }
  }
}

.colorTealLightDefault {
  @apply bg-teal-100 border-teal-100 text-teal-500;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply bg-teal-200 border-teal-200;
    }
  }
}

.colorTealLightOutline {
  @apply border-teal-200 text-teal-500;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply border-teal-300;
    }
  }
}

.colorGray300Default {
  @apply bg-gray-300 border-gray-300 text-gray-800;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply bg-gray-400 border-gray-400;
    }
  }
}

.colorGray300Outline {
  @apply border-gray-300 text-gray-800;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply border-gray-400 text-gray-800;
    }
  }
}

.colorGray800Default {
  @apply bg-gray-800 border-gray-800 text-white;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply bg-gray-600 border-gray-600;
    }
  }
}

.colorGray800Outline {
  @apply border-gray-800 text-gray-800;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply border-gray-600 text-gray-600;
    }
  }
}

.colorRedDefault {
  @apply bg-red-600 border-red-600 text-white;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply bg-red-500 border-red-500;
    }
  }
}

.colorRedOutline {
  @apply border-red-600 text-red-600;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply border-red-400 text-red-400;
    }
  }
}

.colorPurpleDefault {
  @apply bg-purple-700 border-purple-700 text-white;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply bg-purple-700 border-purple-700;
    }
  }
}

.colorPurpleOutline {
  @apply border-purple-800 text-purple-800;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @apply border-purple-600 text-purple-600;
    }
  }
}

.colorFacebookDefault {
  @apply bg-brand-facebook border-brand-facebook text-white;
}

.colorFacebookOutline {
  @apply border-brand-facebook text-brand-facebook;
}

.colorTwitterDefault {
  @apply bg-brand-twitter border-brand-twitter text-white;
}

.colorTwitterOutline {
  @apply border-brand-twitter text-brand-twitter;
}

.colorStravaDefault {
  @apply bg-brand-strava border-brand-strava text-white;
}

.colorStravaOutline {
  @apply border-brand-strava text-brand-strava;
}
